#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.wind-map-container {
  opacity: 1;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 900px;
  z-index: 99;
  pointer-events: none;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

.react-tabs {
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
  overflow-y: hidden;
}

.react-tabs__tab-list {
  border-bottom: 1px solid rgb(220, 220, 220);
  display: flex !important;
  flex-direction: row;
  justify-content: left;
  padding-left: 20px;
  list-style-type: none;
  margin-block-start: none;
  margin-block-end: none;
  margin-inline-start: none;
  margin-inline-end: none;
  padding-inline-start: none;
}

.react-tabs__tab {
  color: rgb(150, 150, 150);
  border-bottom: 2px solid transparent;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;
  padding-bottom: 6px;
  margin: 0px 25px 0px 0px;
  color: #010724;
  font-weight: normal;
  line-height: 140%;
  font-size: 14px;
}

.react-tabs__tab--selected {
  border-color: #3a4ade;
  font-weight: 500;
  color: #3a4ade;
}

.react-tabs__tab--disabled {
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px #3a4ade;
  border-color: #3a4ade;
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  padding: 0px 20px 20px 20px;
  overflow-y: auto;
  height: 100%;
  display: none;
}

.react-tabs__tab-panel--selected {
  display: flex;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.mapboxgl-map.mouse-pointer .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: pointer;
}
.mapboxgl-map.mouse-move .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: move;
}
.mapboxgl-map.mouse-add .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: crosshair;
}
.mapboxgl-map.mouse-move.mode-direct_select
  .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.mapboxgl-map.mode-direct_select.feature-vertex.mouse-move
  .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: move;
}
.mapboxgl-map.mode-direct_select.feature-midpoint.mouse-pointer
  .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: cell;
}
.mapboxgl-map.mode-direct_select.feature-feature.mouse-move
  .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: move;
}
.mapboxgl-map.mode-static.mouse-pointer
  .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.custom-marker {
  background-size: cover;
  width: 22px;
  height: 27px;
  cursor: pointer;
}

.__react_component_tooltip {
  border-radius: 4px;
  padding: 6px 8px;
  text-align: left !important;
}

.__react_component_tooltip > .multi-line {
  text-align: left !important;
}
